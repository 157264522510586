// functions copy/pasted from https://gitlab.ezugi.local/BS/OMSBS/blob/f707b26/layout/js/common.js
function gcd(a, b) {
  if (!b) return a;
  return gcd(b, a % b);
}

function numberWithCommas(x, precision) {
  if (!precision) {
    x = x.toFixed(2);
  } else {
    x = x.toFixed(precision);
  }
  const n = x.toString().split('.');
  n[0] = n[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return n.join('.');
}

function decimalToFraction(decimal) {
  const denominator = 100;
  const numerator = parseInt(decimal * denominator - denominator, 10);
  const divisor = gcd(numerator, denominator);

  let str;
  if (
    decimal > 1
    && decimal < 3
    && decimal !== 2
    && denominator / divisor < 10
  ) {
    str = `${Math.floor(parseInt(numberWithCommas((decimal - 1) * 10, 0), 10))
    } / ${
      numberWithCommas(10, 0)}`;
  } else {
    str = `${numberWithCommas(numerator / divisor, 0)
    } / ${
      numberWithCommas(denominator / divisor, 0)}`;
  }
  return str;
}
// /functions copy/pasted from https://gitlab.ezugi.local/BS/OMSBS/blob/f707b26/layout/js/common.js


// in omsbs, PAYOUTS_FORMAT is defined in config file
//    (see https://gitlab.ezugi.local/BS/OMSBS/blob/master/betting_shop/include/define/omsbsdev.ezugi.com.php)
const PAYOUT_TYPE_MULTIPLIER = 'multiplier'; // corresponding to PAYOUTS_FORMAT=1
const PAYOUT_TYPE_FRACTIONAL = 'fraction'; // corresponding to PAYOUTS_FORMAT=3

// payout formatting is similar to the one from omsbs, see:
// https://gitlab.ezugi.local/BS/OMSBS/blob/f707b26/layout/js/common.js#L1077-1092 and
// https://gitlab.ezugi.local/BS/OMSBS/blob/f707b26/betting_shop/include/class/bshelper.class.php#L278-291
function formatPayout(value, type) {
  const precision = null;
  if (type === PAYOUT_TYPE_MULTIPLIER) {
    return `x${numberWithCommas(value + 1, precision)}`;
  }

  if (type === PAYOUT_TYPE_FRACTIONAL) {
    return decimalToFraction(value + 1);
  }

  return `1:${numberWithCommas(value + 1, precision)}`;
}

export function formatPayouts(list = [], type) {
  const formatList = list.reduce(
    (acc, p) => {
      const payouts = JSON.parse(p.payout);
      return { ...acc, [+p.IndexID]: formatPayout(payouts.option1 * 1, type), };
    },
    {}
  );
  return formatList;
}
